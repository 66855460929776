import React, { ReactNode } from 'react';
import { ContainerStyled, ContainerLabelStyled } from './Container.style';

interface props {
    label?: string;
    color: string;
    children?: ReactNode;
}

export const Container = ({ label, color, children }: props) => {
  return (
    <ContainerStyled color={color}>
      {label ? (
        <ContainerLabelStyled color={color}>
          {label}
        </ContainerLabelStyled>
      ) : null}
      {children}
    </ContainerStyled>
  );
};
