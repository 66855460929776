import React, { forwardRef } from 'react';
import { Button as ButtonChakra, ButtonProps } from '@chakra-ui/react';

export const Button: React.FC<ButtonProps & { href?: string }> = forwardRef<
    typeof ButtonChakra,
    ButtonProps
>(({ children, colorScheme, size, w, ...props }, ref) => {
  return (
    <ButtonChakra
      ref={ref}
      {...props}
      borderRadius="32px"
      fontWeight={500}
      colorScheme={colorScheme || 'blue'}
      fontSize="md"
      size={size || 'lg'}
      w={w || '100%'}
      boxShadow={'0px 4px 26px rgba(0, 0, 0, 0.25);'}
    >
      {children}
    </ButtonChakra>
  );
});
Button.displayName = 'Button';
