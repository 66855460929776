import React, { forwardRef, ReactNode } from 'react';
import {
  Input as InputChakra,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';

interface InputProp extends InputProps {
    leftElement?: ReactNode;
    rightElement?: ReactNode;
    mask?: string;
    maskChar?: string | null;
    formatChars?: object;
}

export const Input: React.FC<InputProp> = forwardRef<
    typeof InputChakra,
    InputProp
>(({ leftElement, rightElement, variant, size, fontSize, ...props }, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <InputGroup {...{ ref } as any}>
      {leftElement ? (
        <InputLeftElement pointerEvents="none" h="100%">
          {leftElement}
        </InputLeftElement>
      ) : null}
      {rightElement ? (
        <InputRightElement pointerEvents="none" h="100%">
          {rightElement}
        </InputRightElement>
      ) : null}
      <InputChakra
        {...props}
        variant={variant || 'flushed'}
        size={size || 'lg'}
        fontSize={fontSize || 16}
      />
    </InputGroup>
  );
});
Input.displayName = 'Input';