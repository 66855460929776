import React, { forwardRef, ReactNode } from 'react';
import { ChakraProvider } from '@chakra-ui/react';

interface ProviderProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    theme?: Record<string, any>;
    children: ReactNode;
}

export const Provider = forwardRef<typeof ChakraProvider, ProviderProps>(
  ({ children, theme, ...props }) => {
    return (
      <ChakraProvider {...props} theme={theme}>
        {children}
      </ChakraProvider>
    );
  }
);
Provider.displayName = 'Provider';
